import React, { useRef } from 'react'
import useTimeline from 'hooks/useTimeline'

import Image from 'components/shared/Image'
import { Title } from 'components/typo'
import Button from 'components/shared/Button'

import classnames from 'classnames/bind'
import { ReactComponent as CloseIcon } from './closeIcon.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const LogoPopin = ({ className, title, asset, links, close }) => {
  const iconRef = useRef()
  const tl = useTimeline({ paused: true }, (tl) => {
    tl.fromTo(
      iconRef.current,
      { z: -500 },
      {
        z: 0,
        transformPerspective: 1000,
        force3D: true,
        duration: 1,
        immediateRender: false,
        ease: 'elastic.out(0.7, 0.8)',
      }
    )
  })

  return (
    <div className={cx(className, css.LogoPopin)}>
      <div
        className={css.closeIcon}
        onMouseEnter={() => tl.restart()}
        onClick={close}
      >
        <CloseIcon ref={iconRef} />
      </div>
      <div className={css.content}>
        {title && (
          <Title tag="h2" size="small" className={css.title}>
            {title}
          </Title>
        )}
        {asset && <Image className={css.image} {...asset} />}
        {links && (
          <div className={css.buttonsWrapper}>
            {links.map((link, index) => (
              <Button
                buttonWrapperClassName={css.button}
                key={`logo-popin-link-${index}`}
                {...link}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

LogoPopin.defaultProps = {}

export default LogoPopin

import { useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import delve from 'dlv'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

gsap.registerPlugin(ScrollTrigger)

function useScrollTrigger(ref, params, deps = []) {
  const scrollTrigger = useRef()

  useIsomorphicLayoutEffect(() => {
    scrollTrigger.current = ScrollTrigger.create({
      trigger: delve(ref, 'current'),
      ...params,
      endTrigger:
        delve(params, 'endTriggerRef.current') || delve(params, 'endTrigger'),
      pin: delve(params, 'pinRef.current') ?? delve(params, 'pin'),
      scroller:
        delve(params, 'scrollerRef.current') || delve(params, 'scroller'),
    })

    return () => {
      scrollTrigger.current.kill()
      scrollTrigger.current = null
    }
  }, deps)

  return scrollTrigger.current
}

export default useScrollTrigger

import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

import { useRouter } from 'next/router'

export default function useHubspotChat() {
  const router = useRouter()

  useIsomorphicLayoutEffect(() => {
    const handleRouteChange = () => {
      window.HubSpotConversations?.resetAndReloadWidget()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
}

import React, { useRef, useCallback } from 'react'
import { Title, RichText, Label } from 'components/typo'
import NewsWithLink from 'components/shared/NewsWithLink'
import classnames from 'classnames/bind'
import useRefs from 'hooks/useRefs'
import useLabelTitleTextAnimation from 'hooks/useLabelTitleTextAnimation'
import useAnimateInView from 'hooks/useAnimateInView'
import useComponentSize from '@rehooks/component-size'
import useResizeIndex from 'hooks/useResizeIndex'
import css from './styles.module.scss'
import Image from 'components/shared/Image'
import Text from 'components/typo/Text'
import Button from 'components/shared/Button'
import CliBoxCopy from 'components/shared/CliBoxCopy'

const cx = classnames.bind(css)

const Strapi5HeroContent = ({
  label,
  title,
  text,
  features,
  className,
  titleProps,
  textProps,
  labelProps,
  theme,
  ctaBanner,
  smallTextWithLink,
  button,
  cliContent,
  newsWithLink,
  newsWithLinkCustomTextColor,
}) => {
  const componentRef = useRef()
  const elementRef = useRef()
  const labelRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const childrenContainerRef = useRef()
  const linksRef = useRefs(smallTextWithLink && smallTextWithLink.length)
  const { width, height } = useComponentSize(componentRef)
  const resizeIndex = useResizeIndex()
  const tl = useLabelTitleTextAnimation(
    {
      elementRef,
      components: [
        {
          key: 'label',
          ref: labelRef,
          type: 'splitText',
        },
        {
          key: 'title',
          ref: titleRef,
          type: 'splitText',
        },
        {
          key: 'text',
          ref: textRef,
          type: 'splitText',
        },
        {
          ref: childrenContainerRef,
          type: 'zoom',
        },
        {
          ref: linksRef,
          type: 'zoom',
        },
      ],
      wordsClass: css.word,
      delay: 0.3,
    },
    [width, height, resizeIndex]
  )

  const [inViewRef] = useAnimateInView(
    {
      triggerOnce: true,
      ref: componentRef,
      animateIn: () => tl.restart(true),
    },
    [width, height, resizeIndex]
  )

  const setRef = useCallback((node) => {
    elementRef.current = node
    inViewRef(node)
  }, [])

  const renderRichText = () => {
    const { className, ...rest } = textProps

    return (
      <RichText
        forwardRef={textRef}
        type="Text"
        text={text}
        linksTheme={theme}
        markdownProps={{
          disallowedTypes: ['paragraph'],
          unwrapDisallowed: true,
        }}
        className={cx(css.text, className)}
        carriageReturn
        {...rest}
      />
    )
  }

  return (
    <div
      className={cx(css.LabelTitleText, className, {
        ctaBanner,
      })}
      ref={setRef}
    >
      {label && (
        <Label
          tag="div"
          className={cx(css.label, labelProps?.className)}
          theme={theme}
          {...labelProps}
        >
          <div className={css.animateLabel} ref={labelRef}>
            {label}
          </div>
        </Label>
      )}
      {newsWithLink && (
        <NewsWithLink
          {...newsWithLink}
          newsWithLinkCustomTextColor={newsWithLinkCustomTextColor}
          isStrapi5Hero
        />
      )}
      <div className={css.textContainer}>
        {title && (
          <Title
            size="large"
            {...titleProps}
            className={cx(css.title, titleProps?.className)}
          >
            <div className={css.titleContainer}>
              <span ref={titleRef}>{title}</span>
            </div>
          </Title>
        )}
        {text && renderRichText()}
      </div>
      <div className={css.container}>
        {cliContent && <CliBoxCopy cliContent={cliContent} theme="dark" />}

        <div className={css.orContainer}>
          <div className={css.divider} />
          <span className={css.orText}>or</span>
          <div className={css.divider} />
        </div>
        {button && button[0].link.href && (
          <div className={css.textContainer}>
            <Button
              theme="blue"
              className={css.btn}
              buttonWrapperClassName={css.btn}
              buttonClassName={css.buttonClass}
              href={button[0].link.href}
              target={button[0].target ?? 'blank'}
            >
              {button[0].link.text}
            </Button>
          </div>
        )}
      </div>
      <div className={css.featuresRow}>
        {features &&
          features.map((feature) => (
            <div className={css.oneFeature} key={feature.id}>
              <Image
                className={css.featureIcon}
                media={{ width: 16, height: 16 }}
                fluid
                {...feature.icon}
                alt={
                  feature.icon?.media?.data?.attributes?.alternativeText ??
                  'icon-image'
                }
              />
              <Text className={css.featureTitle}>{feature.title}</Text>
            </div>
          ))}
      </div>
    </div>
  )
}

Strapi5HeroContent.defaultProps = {
  textProps: {},
  titleProps: {},
  theme: 'purple',
}

export default Strapi5HeroContent

import React, { useRef, useState } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

import { useClickAway } from 'react-use'
import { Text, RichText } from 'components/typo'
import Link from 'components/shared/Link'
import useBodyLockScroll from 'hooks/useBodyLockScroll'
import classnames from 'classnames/bind'
import algolia from 'services/resource-center-algolia'
import { algoliaResoucesAdapter } from 'adapters/resource-center'
import css from './styles.module.scss'
import { ReactComponent as Search } from './search.svg'
import InputText from '../InputText'

const cx = classnames.bind(css)

const NoResults = ({ value }) => {
  const renderString = () => {
    if (value !== '') {
      return (
        <span>
          for <span className={css.searchedTerm}>"{value}"</span>
        </span>
      )
    }

    return ''
  }

  return (
    <div className={css.noResults}>
      <Search className={css.noResultsIcon} />
      <Text size="medium">No results {renderString()}</Text>
    </div>
  )
}

const search = async (params) => {
  const [indices, searchString] = params
  const algoliaIndices = indices.map((indice) => algolia(indice.name))

  const res = await Promise.all(
    algoliaIndices.map((algoliaIndice) =>
      algoliaIndice
        .search(searchString, {
          page: 0,
        })
        .then((res) => ({
          hits: res.hits,
          indice: algoliaIndice.indexName,
        }))
    )
  )

  return res
    .filter((obj) => obj.hits.length > 0)
    .map((obj) => {
      const indice = indices.find((i) => i.name === obj.indice)

      return {
        hits: algoliaResoucesAdapter(
          obj.hits.filter((x) => x.publishedAt !== null),
          indice
        ),
        indice,
      }
    })
}

const SearchResults = ({ indices, closePopin, className }) => {
  const [searchString, setSearchString] = useState('')
  const [results, setResults] = useState(null)
  const ressourceType = indices.length === 1 ? indices[0].label : 'a resource'
  const initialRender = useRef(true)
  const wrapperRef = useRef(null)
  const contentRef = useRef(null)
  const inputRef = useRef(null)

  useIsomorphicLayoutEffect(() => {
    if (!initialRender.current) {
      if (searchStringIsNull(searchString)) return setResults('')
      fetchResults()
    }
  }, [searchString])

  useIsomorphicLayoutEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [inputRef.current])

  useIsomorphicLayoutEffect(() => {
    initialRender.current = false
  }, [])

  const onEscDown = (e) => {
    if (e.keyCode == 27) close()
  }

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('keydown', onEscDown)

    return () => {
      window.removeEventListener('keydown', onEscDown)
    }
  }, [])

  const searchStringIsNull = (searchString) => {
    if (searchString !== '') return false

    return true
  }

  const fetchResults = async () => {
    const hits = await search([indices, searchString])
    setResults(hits)
  }

  const close = () => {
    setSearchString('')
    closePopin()
  }

  useBodyLockScroll(true, contentRef)
  useClickAway(wrapperRef, () => close())

  return (
    <div className={cx(className, css.SearchResults)}>
      <div ref={wrapperRef} className={css.wrapper}>
        <div className={css.inputWrapper}>
          <InputText
            ref={inputRef}
            placeholder={`Search for ${ressourceType}`}
            aria-label={`Search for ${ressourceType}`}
            onChange={(newSearchString) => {
              if (newSearchString !== '') setSearchString(newSearchString)
              else setSearchString('')
            }}
          />
          <button onClick={close} className={css.inputButton}>
            <Text size="smaller">Cancel</Text>
          </button>
        </div>
        <div ref={contentRef} className={css.content}>
          {results && results.length > 0 ? (
            results.map((result) => (
              <div className={css.group} key={result.indice}>
                {result.indice && (
                  <Text size="small" theme="dark" className={css.title}>
                    {result.indice.label}
                  </Text>
                )}
                {result.hits &&
                  result.hits.map((hit) => {
                    if (hit) {
                      const isLink = hit.link && hit.link.href
                      const Wrapper = isLink ? Link : Text
                      const { name, label } = result.indice
                      const isDocumentation =
                        name === 'documentation' && hit.type
                      const icon = isDocumentation ? hit.type : name

                      return (
                        <Wrapper
                          key={hit.title + hit.link.href}
                          className={css.hit}
                          {...hit.link}
                        >
                          {indices.length !== 1 && (
                            <div className={cx(css.icon, icon)}>
                              <img
                                src={`./assets/icons/resource-center/${icon}.svg`}
                                alt={`${label} icon`}
                              />
                            </div>
                          )}
                          <div className={css.resultTextContent}>
                            {hit.title && (
                              <RichText
                                className={css.resultTitle}
                                size="small"
                                text={hit.title}
                              />
                            )}
                            {hit.subtitle && (
                              <RichText
                                className={css.resultSubtitle}
                                size="smaller"
                                text={hit.subtitle}
                              />
                            )}
                          </div>
                        </Wrapper>
                      )
                    }
                  })}
              </div>
            ))
          ) : (
            <NoResults value={searchString} />
          )}
        </div>
      </div>
    </div>
  )
}

SearchResults.defaultProps = {}

export default SearchResults

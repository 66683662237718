import React from 'react'

import { Text } from 'components/typo'
import IconLinkList from 'components/shared/IconLinkList'

import css from './styles.module.scss'

const SocialLinks = ({ text, socials, darkMode }) => {
  return (
    <div className={css.SocialLinks}>
      <Text
        theme={darkMode ? 'grey-500' : 'blue-haze'}
        size="smaller"
        className={css.text}
      >
        {text}
      </Text>
      <IconLinkList links={socials} />
    </div>
  )
}

export default SocialLinks

import React from 'react'

import classnames from 'classnames/bind'
import css from './styles.module.scss'
import { useHasStickyBarProviderContext } from '../../HasStickyBarProvider'
import { useHasScrolledContext } from '../../HasScrolledProvider'

const cx = classnames.bind(css)

const HeaderBackground = ({ className, children, darkMode, isLaunchWeek }) => {
  const [hasStickyBar] = useHasStickyBarProviderContext()
  const [hasScrolled] = useHasScrolledContext()
  
return (
  <div
    className={cx(className, css.HeaderBackground, {
        hasStickyBar,
        hasScrolled,
        darkMode,
        isLaunchWeek
      })}
  >
    {children}
  </div>
  )
}

HeaderBackground.defaultProps = {}

export default HeaderBackground

import React, { Component } from 'react'
import classnames from 'classnames/bind'
import Link from 'components/shared/Link'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export default class MenuColumnLink extends Component {
  render() {
    const { link, darkMode } = this.props

    return (
      <li className={css.MenuColumnLink}>
        <Link className={cx(css.link, { darkMode })} {...link} />
      </li>
    )
  }
}

import React from 'react'
import classnames from 'classnames/bind'
import { useWindowSize } from 'react-use'
import SingleAnimation from 'components/slices/SingleAnimation'
import Layout from 'components/shared/Layout'
import Strapi5HeroContent from 'components/shared/Strapi5HeroContent'
import ClientLogo from 'components/shared/ClientLogo'
import css from './styles.module.scss'

const cx = classnames.bind(css)
const Strapi5Hero = ({
  className,
  intro,
  brands,
  forwardRef,
  animation,
  animations,
  button,
  children,
  features,
}) => {
  const { width } = useWindowSize()
  return (
    <section ref={forwardRef} className={cx(css.Hero, className)}>
      <div className={css.background}>
        <img
          src="/assets/use-case/strapi5_hero.svg"
          alt="shape"
          className={css.img}
          loading="lazy"
        />
      </div>

      <Layout type="wrapper" className={css.heroWrapper}>
        <Layout type="innerWrapper">
          {intro && (
            <Strapi5HeroContent
              titleProps={{
                theme: 'white',
                size: 'title-hero',
                tag: 'h1',
              }}
              textProps={{
                theme: 'white',
                className: css.text,
                tag: 'h2',
              }}
              textWithLinkProps={{
                theme: 'white',
              }}
              center={width > 992}
              className={{
                textAlign: 'left',
              }}
              newsWithLinkCustomTextColor="white"
              {...intro}
              features={features}
            />
          )}
          {children}
        </Layout>
        {animations && (
          <div className={css.animations}>
            {animations.map((oneAnimation, index) =>
              oneAnimation.media.data.attributes.mime.includes('video') ? (
                <div className={css.oneAnimation}>
                  <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    className={css.oneVideo}
                  >
                    <source
                      src={oneAnimation.media.data.attributes.url}
                      type={oneAnimation.media.data.attributes.mime}
                    />
                  </video>
                </div>
              ) : null
            )}
          </div>
        )}

        <div className={css.animations}>
          {animation &&
            animation.map((singleAnimation, index) => {
              return (
                <SingleAnimation
                  key={index}
                  className={index ? '' : css.singleAnimation}
                  layout={css.border}
                  {...singleAnimation}
                  index={index}
                />
              )
            })}
        </div>
        <div className={css.clientsLogos}>
          {brands &&
            brands.brands.map((brand, index) => {
              return <ClientLogo key={index} {...brand} isStrapi5Hero />
            })}
        </div>
      </Layout>
    </section>
  )
}

Strapi5Hero.defaultProps = {
  withTopBackgroundImage: true,
}

export default Strapi5Hero

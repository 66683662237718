import delve from 'dlv'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { createContext, useContext } from 'react'

const SeoContext = createContext({
  title: '',
  description: '',
  url: '',
})

const SeoProvider = ({ defaultSeo, pageSeo, children }) => {
  const title =
    delve(pageSeo, 'metaTitle') || delve(defaultSeo, 'metaTitle', '')

  const suffix = delve(defaultSeo, 'metaTitleSuffix') || ''
  const ogType = delve(pageSeo, 'ogType', 'website')

  const creator = delve(pageSeo, 'creator', '@strapijs')
  const cardStyle = delve(pageSeo, 'cardStyle', 'summary')

  const metaRobots = delve(pageSeo, 'metaRobots') || ''

  const description =
    delve(pageSeo, 'metaDescription') ||
    delve(defaultSeo, 'metaDescription') ||
    ''

  let metaImage =
    delve(pageSeo, 'metaImage.data.attributes.url') ||
    delve(defaultSeo, 'metaImage.data.attributes.url')

  if (metaImage && !metaImage.startsWith('http')) {
    metaImage = `${process.env.NEXT_PUBLIC_IMAGE_URL}${metaImage}`
  }

  const createdAt = delve(pageSeo, 'createdAt', '')
  const updatedAt = delve(pageSeo, 'updatedAt', '')

  const { asPath, query } = useRouter()
  const page = delve(query, 'page')
  const pageTitleString = page ? ` - Page ${page}` : ''

  return (
    <SeoContext.Provider
      value={{
        title: `${title}${pageTitleString}${suffix}`,
        description,
        url: pageSeo?.marketplaceUrl || `${process.env.WEBSITE_URL}${asPath}`,
        metaRobots,
        creator,
        ogType,
        cardStyle,
        metaImage: metaImage,
        createdAt,
        updatedAt,
      }}
    >
      {children}
    </SeoContext.Provider>
  )
}

const Seo = () => {
  const {
    title,
    description,
    url,
    metaImage,
    metaRobots,
    creator,
    ogType,
    cardStyle,
  } = useContext(SeoContext)
  const { asPath } = useRouter()

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      <meta name="twitter:card" content={cardStyle} />
      <meta name="twitter:site" content="@strapijs" />
      <meta name="twitter:creator" content={creator} />
      <meta name="og:type" content={ogType} />
      <meta
        data-hid="twitter:title"
        name="twitter:title"
        property="twitter:title"
        content={title}
      />
      <meta
        data-hid="twitter:description"
        name="twitter:description"
        property="twitter:description"
        content={description}
      />
      <meta
        data-hid="twitter:image"
        name="twitter:image"
        property="twitter:image"
        content={metaImage}
      />
      <meta
        data-hid="twitter:image:alt"
        name="twitter:image:alt"
        property="twitter:image:alt"
        content={metaImage}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:url"
        name="og:url"
        property="og:url"
        content={url}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:title"
        name="og:title"
        property="og:title"
        content={title}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:description"
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:image"
        name="og:image"
        property="og:image"
        content={metaImage}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:image:secure_url"
        name="og:image:secure_url"
        property="og:image:secure_url"
        content={metaImage}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        data-hid="og:image:alt"
        name="og:image:alt"
        property="og:image:alt"
        content={title}
      />
      {asPath.includes('/pricing') ? (
        <link rel="canonical" href={'https://strapi.io/pricing'} />
      ) : (
        <link rel="canonical" href={url} />
      )}

      {metaRobots &&
        metaRobots.split(',').map((robot) => {
          if (robot.includes('noindex')) {
            return (
              <>
                <meta name="robots" content="noindex" />
                <meta name="googlebot" content="noindex" />
              </>
            )
          }
        })}
    </Head>
  )
}

Seo.defaultProps = {}

export { default as Microdata } from './Microdata'
export { default as SponsoredScript } from './SponsoredScript'
export { SeoProvider, Seo, SeoContext }

import React, { cloneElement } from 'react'

import Iframe from 'components/shared/Iframe'
import NewCta from 'components/slices/NewCta'

import css from './styles.module.scss'

const Html = ({ element }) => {
  switch (element.type) {
    case 'div':
      return <div className={css.iframe}>{element}</div>
    case 'cta':
      const { title, text, buttontext, buttonlink } = element.props
      if (title && text && buttontext && buttonlink) {
        return (
          <NewCta
            title={title}
            text={text}
            button={[
              {
                theme: 'blue',
                link: {
                  href: buttonlink,
                  text: buttontext,
                  icons: [],
                },
              },
            ]}
          ></NewCta>
        )
      } else {
        return <></>
      }

    case 'iframe':
      return (
        <Iframe className={css.iframe}>
          {cloneElement(element, {
            // fix cookie-bot blocking
            // https://support.cookiebot.com/hc/en-us/articles/360003790854-Iframe-cookie-consent-with-YouTube-example
            'data-cookieconsent': 'marketing',
          })}
        </Iframe>
      )
    default:
      return element
  }
}

export default Html

import { gsap } from 'gsap'

import useTimeline from 'hooks/useTimeline'
import useSplitTexts from 'hooks/useSplitTexts'

const config = {
  splitText: {
    from: {
      z: -20,
      y: 30,
    },
    to: {
      z: 0,
      y: 0,
      transformPerspective: 1000,
      force3D: true,
      stagger: 0.01,
      duration: 0.8,
      ease: 'elastic.out(0.75, 0.8)',
    },
    opacity: {
      opacity: 1,
      ease: 'none',
      stagger: 0.01,
      duration: 0.15,
    },
  },
  zoom: {
    from: { z: -300 },
    to: {
      z: 0,
      transformPerspective: 1000,
      duration: 1.2,
      stagger: 0.13,
      ease: 'elastic.out(1, 0.75)',
    },
    opacity: {
      opacity: 1,
      stagger: 0.13,
      duration: 0.2,
      ease: 'none',
    },
  },
}

const useLabelTitleTextAnimation = (
  { elementRef, components, wordsClass, delay = 0 },
  deps
) => {
  const splitRefs = components.filter(
    (component) => component.type === 'splitText'
  )

  const splitTexts = useSplitTexts(
    splitRefs,
    {
      type: 'words',
      wordsClass,
    },
    deps
  )

  const tl = useTimeline(
    {
      delay,
      paused: true,
      restoreTime: true,
    },
    (tl) => {
      const position = '>-0.85'

      gsap.set(elementRef.current, {
        opacity: 1,
      })

      for (const { ref, key, type } of components) {
        if (!ref.current || ref.current.length === 0) continue

        if (type === 'splitText' && splitTexts.current[key]) {
          tl.fromTo(
            splitTexts.current[key].words,
            { opacity: 0 },
            config.splitText.opacity,
            position
          )
          tl.fromTo(
            splitTexts.current[key].words,
            config.splitText.from,
            config.splitText.to,
            '<'
          )
        } else {
          let refs

          if (ref.current.length) {
            refs = ref.current.map((childRef) => childRef.current)
          } else refs = ref.current

          if (refs[0]) {
            tl?.fromTo(refs, { opacity: 0 }, config.zoom.opacity)
            tl?.fromTo(refs, config.zoom.from, config.zoom.to, '<')
          }
        }
      }
    },
    deps
  )

  return tl
}

export default useLabelTitleTextAnimation

import useTimeline from 'hooks/useTimeline'

const useUnderline = (isActive, { ref }) => {
  useTimeline(
    {},
    (tl) => {
      if (isActive) {
        tl.fromTo(
          ref.current,
          { x: '-100%', autoAlpha: 0 },
          {
            x: '0%',
            autoAlpha: 1,
            force3D: true,
            duration: 1.5,
            ease: 'elastic.out(0.3, 0.2)',
          }
        )
      } else {
        tl.to(ref.current, {
          x: '102%',
          duration: 0.8,
          force3D: true,
          ease: 'elastic.out(0.3, 0.3)',
        })
      }
    },
    [isActive, ref]
  )
}

export default useUnderline

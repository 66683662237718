import { useRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

export default function usePrevious(value) {
  const ref = useRef()
  useIsomorphicLayoutEffect(() => {
    ref.current = value
  })
  
return ref.current
}

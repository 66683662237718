import PropTypes from 'prop-types'
import { useCopyToClipboard } from 'react-use'

import CopyIcon from 'components/icons/CopyIcon'
import css from './styles.module.scss'

const CliBox = ({ content }) => {
  const [_, copyToClipboard] = useCopyToClipboard()

  return (
    <div className={css.wrapper}>
      <p>{content}</p>
      <button
        onClick={() => copyToClipboard(content)}
        className={css.copyIcon}
        id="cli-box"
        aria-label="Copy to Clipboard"
      >
        <CopyIcon />
      </button>
    </div>
  )
}

CliBox.propTypes = {
  content: PropTypes.string,
}

CliBox.defaultProps = {
  content: 'npx create-strapi-app@latest my-project',
}

export default CliBox

import classnames from 'classnames/bind'
import Image from "next/legacy/image"
import React from 'react'

import Link from 'components/shared/Link'
import { Text } from 'components/typo'
import SmallArrow from 'icons/SmallArrow'

import typo from 'components/typo/typography.module.scss'
import styles from './styles.module.scss'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const ArrowSection = ({ className, title, icons, text, link, onLinkClick }) => {
  return (
    <li className={cx(css.link, className)}>
      {title && (
        <Text size="smaller" theme="grey-600" className={css.arrowTitle}>
          {title}
        </Text>
      )}
      <div className={css.linkWrapper}>
        {icons && icons.length > 0 && (
          <ul className={css.iconList}>
            {icons?.map((icon, index) => (
              <li key={index} className={css.arrowIcon}>
                <Link href={icon.href} target="_blank" onClick={onLinkClick}>
                  <Image
                    src={`${icon.media.data.attributes.url}`}
                    alt={
                      icon.media?.data?.attributes?.alternativeText ||
                      icon.media?.data?.attributes?.name.split("/").pop()
                    }
                    width={32}
                    height={32}
                  />
                </Link>
              </li>
            ))}
          </ul>
        )}
        <Link className={css.arrowLinkWrapper} {...link} onClick={onLinkClick}>
          <Text
            className={css.arrowLink}
            tag="span"
            size="small"
            theme="grey-900"
          >
            {text}
          </Text>
          <SmallArrow className={css.arrow} theme="grey-900" />
        </Link>
      </div>
    </li>
  )
}

export default ArrowSection

import css from './styles.module.scss'

const Table = ({ children }) => (
  <div className={css.table}>
    <table>
      {children}
    </table>
  </div>
)

export default Table;

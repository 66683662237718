import React from 'react'

export default function CopyIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 7.875H9.625C8.6585 7.875 7.875 8.6585 7.875 9.625V17.5C7.875 18.4665 8.6585 19.25 9.625 19.25H17.5C18.4665 19.25 19.25 18.4665 19.25 17.5V9.625C19.25 8.6585 18.4665 7.875 17.5 7.875Z" stroke="#F6F6F9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.375 14.125H4.5C4.03587 14.125 3.59075 13.9406 3.26256 13.6124C2.93437 13.2842 2.75 12.8391 2.75 12.375V4.5C2.75 4.03587 2.93437 3.59075 3.26256 3.26256C3.59075 2.93437 4.03587 2.75 4.5 2.75H12.375C12.8391 2.75 13.2842 2.93437 13.6124 3.26256C13.9406 3.59075 14.125 4.03587 14.125 4.5V5.375" stroke="#F6F6F9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}


import { useState } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

const cachedScripts = []

function useScript({ src, attributes = {}, removeScriptOnCleanup = false }) {
  const [loaded, setLoaded] = useState()
  const [error, setError] = useState()

  useIsomorphicLayoutEffect(() => {
    // script event listener callbacks for load and error
    const onScriptLoad = () => {
      setLoaded(true)
      setError(false)
    }

    const onScriptError = () => {
      // remove from cache and retry to load
      const index = cachedScripts.indexOf(src)

      if (index >= 0) cachedScripts.splice(index, 1)
      script.remove()

      setLoaded(true)
      setError(true)
    }

    // create script
    let script = document.createElement('script')

    // check if script has already been loaded
    if (src) {
      const cache = cachedScripts.find((c) => c.src === src)

      if (cache && cache.loaded) {
        setLoaded(true)
        setError(false)
      } else {
        // add script to cache
        cachedScripts.push({ loaded: false, src })

        script.src = src
        script.async = true

        Object.keys(attributes).forEach((key) =>
          script.setAttribute(key, attributes[key])
        )

        script.addEventListener('load', onScriptLoad)
        script.addEventListener('error', onScriptError)

        // add script to document body
        document.body.appendChild(script)
      }
      // remove event listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad)
        script.removeEventListener('error', onScriptError)

        if (removeScriptOnCleanup) script.remove()
      }
    }
  }, [src]) // re-run effect if script src changes

  return [loaded, error]
}

export default useScript

import React, { useRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useTimeline from 'hooks/useTimeline'
import { gsap } from 'gsap'
import classNames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classNames.bind(css)

function PopIn({ className, visible, setVisible, children }) {
  const containerRef = useRef()
  const contentRef = useRef()

  const tl = useTimeline(
    { paused: true, onReverseComplete: () => setVisible(false) },
    (tl) => {
      gsap.set(contentRef.current, {
        opacity: 1,
      })

      tl.to(containerRef.current, { visibility: 'visible' })
      tl.fromTo(
        contentRef.current,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.15 }
      )
      tl.fromTo(
        contentRef.current,
        { z: -150 },
        {
          z: 0,
          transformPerspective: 1000,
          duration: 0.45,
          force3D: true,
          ease: 'elastic.out(0.7, 0.75)',
        },
        '<'
      )
    }
  )

  useIsomorphicLayoutEffect(() => {
    if (visible) tl.play()
    else tl.reverse()
  }, [visible])

  const close = () => {
    tl.reverse()
  }

  return (
    <div ref={containerRef} className={cx(className, css.PopIn)}>
      <div ref={contentRef}>{children({ close })}</div>
    </div>
  )
}

export default PopIn

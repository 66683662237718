import React, { useRef } from 'react'
import {
  useSectionsContext,
  useSetActiveSection,
} from 'components/shared/sections/SectionsProvider'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useScrollTrigger from 'src/hooks/useScrollTrigger'

const RegisterSection = ({ tagName: Wrapper = 'h2', id, ...rest }) => {
  const ref = useRef()
  const [, setSectionsState] = useSectionsContext()

  useIsomorphicLayoutEffect(() => {
    setSectionsState((prevSections) => ({
      ...prevSections,
      [id]: {
        text: rest.children,
        ref,
        active: false,
      },
    }))
  }, [])

  const setActiveSection = useSetActiveSection()
  useScrollTrigger(ref, {
    onEnter: () => setActiveSection(id),
    onEnterBack: () => setActiveSection(id),
    start: 'start start+=95',
    end: 'end end+=95',
  })

  return <Wrapper ref={ref} {...rest} />
}

RegisterSection.defaultProps = {}

export default RegisterSection

import React from 'react'
import Link from 'components/shared/Link'
import delve from 'dlv'

import classnames from 'classnames/bind'

import css from './styles.module.scss'
import Image from "next/legacy/image"

const cx = classnames.bind(css)

const Logo = ({
  className,
  onContextMenu,
  url,
  alt = 'Strapi logo',
  headerDarkMode,
  hasScrolled,
}) => (
  <Link
    onContextMenu={onContextMenu}
    className={cx(css.Logo, className)}
    as="/"
    href="/"
  >
    {headerDarkMode ? (
      <>
        <Image
          className={cx(css.img, css.darkMode, css.light, { hasScrolled })}
          alt={alt}
          src="/assets/strapi-logo-light.svg"
          layout='fill'
        />
        <Image
          className={cx(css.img, css.darkMode, css.dark, { hasScrolled })}
          alt={alt}
          src="/assets/strapi-logo-dark.svg"
          layout='fill'
        />
      </>
    ) : (
      <div className={css.img}>
        <Image className={css.img} alt={alt} src={url} layout='fill' objectFit='contain' />
      </div>
    )}
  </Link>
)

const LogoWrapper = ({ dark, customLogo, isLaunchWeek, ...rest }) => {
  const url = delve(customLogo, 'media.url')
  const alt = delve(customLogo, 'alt')
  const local = delve(customLogo, 'local')

  // Format the logo URL according to props (custom logo, dark/light theme)
  // A custom logo can either be stored in Strapi API or in the public folder (e.g: for blog posts pages)
  // The 'local' property determines the path to the file
  const defaultLogo = `/assets/strapi-logo-${dark ? 'dark' : 'light'}.svg`
  const path = local ? '' : process.env.NEXT_PUBLIC_IMAGE_URL
  const formattedUrl = url ? `${path}${url}` : defaultLogo

  return <Logo url={formattedUrl} alt={alt} {...rest} />
}

export default LogoWrapper

import React, { useCallback } from 'react'
import classnames from 'classnames/bind'
import delve from 'dlv'

import { Text } from 'components/typo'
import LinkItem from './LinkItem'

import typo from 'components/typo/typography.module.scss'
import styles from './styles.module.scss'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const LinksSection = React.memo(
  ({ className, title, links, onLinkClick, sectionsLength }) => {
    const handleLinkClick = useCallback(
      (link) => {
        if (onLinkClick) {
          onLinkClick(link)
        }
      },
      [onLinkClick]
    )

    return (
      <div
        className={cx(
          css.LinksSection,
          links.length > 4 && sectionsLength <= 2
            ? css.LinksSectionWide
            : css.LinksSectionNarrow,
          className
        )}
      >
        {title && (
          <Text size="smaller" theme="grey-600" className={css.title}>
            {title}
          </Text>
        )}
        {links && links.length > 0 && (
          <ul
            className={cx(
              links.length > 4 && sectionsLength <= 2
                ? css.LinksSectionGrid
                : css.LinkSectionColumn
            )}
          >
            {links.map((link) => {
              const id = delve(link, 'id')
              const icons = delve(link, 'icons')

              return (
                <LinkItem
                  key={id}
                  id={id}
                  className={css.link}
                  icons={icons}
                  link={link}
                  onLinkClick={() => handleLinkClick(link)}
                />
              )
            })}
          </ul>
        )}
      </div>
    )
  }
)

export default LinksSection

import { useEffect, useLayoutEffect } from 'react'

// Utility to check if the DOM is available
const canUseDom = !!(
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
)

// Custom hook that uses useLayoutEffect on the client and useEffect on the server
const useIsomorphicLayoutEffect = canUseDom ? useLayoutEffect : useEffect

export default useIsomorphicLayoutEffect

import React from 'react'

import { Text } from 'components/typo'
import Image from 'components/shared/Image'
import Link from 'components/shared/Link'

import css from './styles.module.scss'

const DesignStudio = () => {
  return (
    <div className={css.DesignStudio}>
      <Text theme="gray" size="smaller">
        Designed by
      </Text>
      <Link href="https://studiovoila.com/" target="_blank">
        <Image
          className={css.logo}
          media={{ width: 45, height: 14 }}
          src="/assets/voila.svg"
        />
      </Link>
    </div>
  )
}

export default DesignStudio

import { useRef, createRef } from 'react'

function generateRefs(length) {
  return [...Array(length)].map(() => createRef())
}

const useRefs = (length) => {
  const prevLength = useRef(length)
  const refs = useRef(generateRefs(length))

  if (length !== prevLength.current) {
    if (length > prevLength.current) {
      refs.current = [
        ...refs.current,
        ...generateRefs(length - prevLength.current),
      ]
    } else if (length < prevLength.current) {
      if (length === 0) {
        refs.current = []
      } else {
        refs.current = [...refs.current.slice(0, length)]
      }
    }
    prevLength.current = length
  }

  return refs
}

export default useRefs

import React, { useContext } from 'react'
import Head from 'next/head'
import { SeoContext } from '../index'

const prepareStructuredData = (type = 'global', ctx) => {
  switch (type) {
    case 'article':
      return `
        {
          "@context": "http://schema.org",
          "@type": "Article",
          "name": "${ctx.name}",
          "headline": "${ctx.name}",
          "description": "${ctx.description}",
          "image": "${ctx.image}",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${ctx.mainEntityOfPage}"
          },
          "author": {
            "@type": "Person",
            "name": "${ctx.author.name}",
            "image": "${ctx.author.image}"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Strapi",
            "logo": {
              "@type": "ImageObject",
              "url": "${process.env.WEBSITE_URL}/assets/strapi-logo-blog.svg"
            }
          },
          "datePublished": "${ctx.publishedAt}",
          "dateModified": "${ctx.updatedAt}"
        }`
    default:
      return `
        {
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "Organization",
              "@id": "${process.env.WEBSITE_URL}/#organization",
              "name": "Strapi",
              "url": "${process.env.WEBSITE_URL}",
              "sameAs": [],
              "logo": {
                "@type": "ImageObject",
                "@id": "${process.env.WEBSITE_URL}/#logo",
                "inLanguage": "en",
                "url": "${process.env.WEBSITE_URL}/assets/strapi-logo-dark.svg",
                "width": 264,
                "height": 68,
                "caption": "Strapi"
              },
              "image": "${process.env.WEBSITE_URL}/assets/strapi-logo-dark.svg"
            },
            {
              "@type": "WebSite",
              "@id": "${process.env.WEBSITE_URL}/#website",
              "url": "${process.env.WEBSITE_URL}",
              "name": "Strapi",
              "description": "Strapi is the next-gen headless CMS, open-source, javascript, enabling content-rich experiences to be created, managed and exposed to any digital device.",
              "publisher": {
                "@id": "${process.env.WEBSITE_URL}/#organization"
              }
            },
            {
              "@type": "WebPage",
              "@id": "${ctx.url}/#webpage",
              "url": "${ctx.url}",
              "name": "${ctx.title}",
              "isPartOf": {
                "@id": "${process.env.WEBSITE_URL}/#website"
              },
              "about": {
                "@id": "${process.env.WEBSITE_URL}/#organization"
              },
              "primaryImageOfPage": {
                "@id": "${process.env.WEBSITE_URL}/#primaryimage"
              },
              "datePublished": "${ctx.createdAt}",
              "dateModified": "${ctx.updatedAt}",
              "description": "${ctx.description}",
              "inLanguage": "en",
              "potentialAction": [
                {
                  "@type": "ReadAction",
                  "target": ["${process.env.WEBSITE_URL}"]
                }
              ]
            }
          ]
        }`
  }
}

const Microdata = ({ type, data, prepareStructure }) => {
  const context = useContext(SeoContext)
  const structuredData = prepareStructure
    ? prepareStructuredData(type, {
        ...data,
        ...context,
      })
    : JSON.stringify(data)

  return (
    <Head>
      <script type="application/ld+json">{structuredData}</script>
    </Head>
  )
}

Microdata.defaultProps = {
  type: 'global',
  prepareStructure: true,
}

export default Microdata

import classnames from 'classnames/bind'
import Image from 'next/legacy/image'
import React from 'react'

import Link from 'components/shared/Link'
import { Text } from 'components/typo'
import SmallArrow from 'icons/SmallArrow'

import typo from 'components/typo/typography.module.scss'
import styles from './styles.module.scss'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const LinksItem = ({ id, className, icons, link, onLinkClick }) => {
  return (
    <li key={id} className={cx(css.link, className)}>
      <Link key={id} {...link} onClick={onLinkClick}>
        <div className={css.linkItemWrapper}>
          <div className={css.linkItemTextWrapper}>
            <div className={css.linkTitleWrapper}>
              {icons && icons.length > 0 && (
                <Image
                  src={`${icons[0]?.media?.data?.attributes?.url}`}
                  alt={
                    icons?.[0]?.media?.data?.attributes?.alternativeText ||
                    icons?.[0]?.media?.data?.attributes?.name?.split('/').pop()
                  }
                  width={20}
                  className={css.linkIcon}
                  height={20}
                />
              )}
              <Text
                className={css.linkTitle}
                tag="span"
                size="seventeen"
                theme="blue-800"
              >
                {link.text}
              </Text>
            </div>
            <Text className={css.linkSummary} tag="span" size="legend">
              {link.summary}
            </Text>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default LinksItem

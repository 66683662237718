import React from 'react'

import Code from 'components/shared/Code'

const CodeBlock = ({ children, value, language }) => {
  return (
    <Code content={value} language={language}>
      {children}
    </Code>
  )
}

export default CodeBlock

import Link from 'components/shared/Link'
import { Text } from 'src/components/typo'
import css from './styles.module.scss'
import classnames from 'classnames/bind'

const NewsWithLink = ({
  link,
  text,
  badgeText,
  newsWithLinkCustomTextColor,
  isStrapi5Hero,
}) => {
  const cx = classnames.bind(css)
  return (
    <div
      className={cx(css.wrapper, {
        [css.strapi5Wrapper]: isStrapi5Hero,
        [css.marginBottom]: !isStrapi5Hero,
      })}
    >
      <div className={css.contentWrapper}>
      <div
        className={cx(css.tag, {
          [css.tagPink]: isStrapi5Hero,
          [css.tagLavender]: !isStrapi5Hero,
        })}
      >
        {badgeText}
      </div>
      <Text
        theme={
          newsWithLinkCustomTextColor ? newsWithLinkCustomTextColor : 'gray'
        }
        className={cx({
          [css.text]: isStrapi5Hero,
        })}
      >
        {text}
      </Text>
      </div>
      <Link
        {...link}
        className={cx(css.link, {
          [css.linkPink]: isStrapi5Hero,
          [css.linkLavender]: !isStrapi5Hero,
        })}
      />
    </div>
  )
}

export default NewsWithLink

const algoliasearch = require('algoliasearch/lite')

function algolia(index) {
  switch (index) {
    case 'showcases':
    case 'tutorials':
    case 'blog_posts':
    case 'documentation':
    case 'partners':
    case 'starters':
      return algoliasearch(
        process.env.ALGOLIA_APP_ID,
        process.env.ALGOLIA_API_KEY
      ).initIndex(index)
    default:
      return algoliasearch(
        process.env.ALGOLIA_ARTICLES_APP_ID,
        process.env.ALGOLIA_ARTICLES_API_KEY
      ).initIndex(index)
  }
}

module.exports = algolia

import React from 'react'
import classnames from 'classnames/bind'

import IconLink from './IconLink'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IconLinkList = ({ className, links, ...props }) => {
  return (
    <ul className={cx(className, css.IconLinkList)}>
      {links.map((link, index) => {
        return <IconLink key={index} {...props} {...link} />
      })}
    </ul>
  )
}

IconLinkList.defaultProps = {}

export default IconLinkList

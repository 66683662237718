import React, { createContext, useContext, useState } from 'react'

export const SectionsContext = createContext([{}, () => {}])

export default function SectionsProvsectionIder({ children }) {
  const useSectionsState = useState({})
  
return (
  <SectionsContext.Provider value={useSectionsState}>
    {children}
  </SectionsContext.Provider>
  )
}

export function useSectionsContext() {
  return useContext(SectionsContext)
}

export function useSetActiveSection() {
  const [, setSectionsState] = useSectionsContext()

  return (sectionId) =>
    setSectionsState((prevSections) => {
      const sections = { ...prevSections }
      Object.keys(sections).forEach((sectionKey) => {
        sections[sectionKey].active = false
      })

      return {
        ...sections,
        [sectionId]: {
          ...prevSections[sectionId],
          active: true,
        },
      }
    })
}

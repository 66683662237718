import React from 'react'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
/**
 * Every language needs to be imported for tree shaking
 */
import js from 'react-syntax-highlighter/dist/esm/languages/prism/javascript'
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx'
import ts from 'react-syntax-highlighter/dist/esm/languages/prism/typescript'
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx'
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash'
import dart from 'react-syntax-highlighter/dist/esm/languages/prism/dart'
/**
 * Styles
 */
import tomorrow from 'react-syntax-highlighter/dist/cjs/styles/prism/tomorrow'
import transparent from './transparent'
import light from './light'
import green from './green'
import purple from './purple'

const themes = {
  tomorrow,
  transparent,
  light,
  green,
  purple,
}

const languages = {
  js,
  jsx,
  ts,
  tsx,
  bash,
  dart,
}

/**
 * Every language needs to be registered for tree shaking
 */
for (const language in languages) {
  SyntaxHighlighter.registerLanguage(language, languages[language])
}

export default function SyntaxHighlighterComponent({
  children,
  customStyle,
  language,
  theme,
}) {
  const getLanguage = !language
    ? undefined
    : languages[language]
    ? language
    : 'js'

  const languagesWithoutLineNumbers = ['bash', 'sh', 'zsh', undefined]
  const getShowLineNumbers = !languagesWithoutLineNumbers.includes(language)

  return (
    <SyntaxHighlighter
      language={getLanguage}
      style={themes[theme]}
      customStyle={customStyle}
      showLineNumbers={getShowLineNumbers}
    >
      {children}
    </SyntaxHighlighter>
  )
}

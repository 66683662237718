import React, { Component } from 'react'

import { Text } from 'components/typo'
import MenuColumnLink from '../MenuColumnLink'

import css from './styles.module.scss'

export default class MenuColumn extends Component {
  render() {
    const { title, links, darkMode } = this.props

    return (
      <div className={css.MenuColumn}>
        <Text
          className={css.sectionTitle}
          theme={darkMode ? 'white' : 'dark'}
          tag="p"
          size="small"
        >
          {title}
        </Text>
        <ul>
          {links.map((link, id) => {
            return <MenuColumnLink key={id} link={link} darkMode={darkMode} />
          })}
        </ul>
      </div>
    )
  }
}

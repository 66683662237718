import React, { useRef } from 'react'

import Layout from 'components/shared/Layout'
import LabelTitleText from 'components/shared/LabelTitleText'
import Img from 'components/shared/Image/Img'
import classnames from 'classnames/bind'
import useAnimateInView from 'src/hooks/useAnimateInView'
import useTimeline from 'src/hooks/useTimeline'
import patternLeftBottom from './pattern-left-bottom.svg'
import patternRightBottom from './pattern-right-bottom.svg'
import patternLeftTop from './pattern-left-top.svg'
import patternRightTop from './pattern-right-top.svg'

import css from './styles.module.scss'
import buttonCSS from '../../shared/Button/styles.module.scss'

const cx = classnames.bind(css)

// Commenting the animation as it cause the button to not always be clickable. This should be fixed in the future.

// const config = {
//   from: {
//     z: -20,
//     y: 30,
//   },
//   to: {
//     z: 0,
//     y: 0,
//     transformPerspective: 1000,
//     force3D: true,
//     stagger: 0.01,
//     duration: 1,
//     ease: 'elastic.out(0.75, 0.8)',
//   },
//   opacity: {
//     opacity: 1,
//     ease: 'none',
//     stagger: 0.01,
//     duration: 0.15,
//   },
// }

const NewCta = ({ text, title, button }) => {
  // check checkIfLinks
  const content = { title, text, button, smallTextWithLink: [] }
  // const componentRef = useRef()
  // const animateIn = useTimeline({ paused: true }, (tl) => {
  //   if (componentRef.current) {
  //     tl.fromTo(componentRef.current, { opacity: 0 }, config.opacity)
  //     tl.fromTo(componentRef.current, config.from, config.to, '<')
  //   }
  // })
  // const [inViewRef] = useAnimateInView({
  //   animateIn: () => animateIn.restart(0.3),
  //   ref: componentRef,
  //   triggerOnce: true,
  // })

  return (
    <Layout className={cx(css.NewCta)} type="wrapperv4">
      <div className={css.contentWrapper}>
        <LabelTitleText
          center
          titleProps={{ theme: 'white', size: 'medium', className: css.title }}
          textProps={{ theme: 'grey-100', className: css.text }}
          buttonProps={{ theme: 'blue', className: css.button }}
          noAnimation
          {...content}
        />
      </div>
      <Img
        src={patternLeftTop}
        loading={false}
        className={cx(css.image, css.imageTopLeft)}
      />

      <Img
        src={patternRightTop}
        loading={false}
        className={cx(css.image, css.imageTopRight)}
      />
      <Img
        src={patternLeftBottom}
        loading={false}
        className={cx(css.image, css.imageBottomLeft)}
      />
      <Img
        media={{ width: 240, height: 144 }}
        src={patternRightBottom}
        loading={false}
        className={cx(css.image, css.imageBottomRight)}
      />
    </Layout>
  )
}

export default NewCta

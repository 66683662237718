import { useRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { gsap } from 'gsap'

const useTimeline = ({ restoreTime, ...config }, buildTl, deps = []) => {
  const tl = useRef(gsap.timeline(config))
  const prevTime = useRef()

  useIsomorphicLayoutEffect(() => {
    buildTl(tl.current)
    restoreTime && prevTime.current && tl.current.seek(prevTime.current)
    
return () => {
      prevTime.current = tl.current.time()
      tl.current.clear()
    }
  }, deps)

  useIsomorphicLayoutEffect(() => {
    return () => {
      tl.current.kill()
    }
  }, [])

  return tl.current
}

export default useTimeline

import { useRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { SplitText } from 'gsap/dist/SplitText'
import { gsap } from 'gsap'

gsap.registerPlugin(SplitText)

const useSplitTexts = (components, opts, deps = []) => {
  const splitTextInstances = useRef({})

  useIsomorphicLayoutEffect(() => {
    for (const { ref, key } of components) {
      if (ref.current) {
        splitTextInstances.current = {
          ...splitTextInstances.current,
          [key]: new SplitText(ref.current, opts),
        }
      }
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    for (const key in splitTextInstances.current) {
      splitTextInstances.current[key].split()
    }
    
return () => {
      for (const key in splitTextInstances.current) {
        splitTextInstances.current[key].revert()
      }
    }
  }, deps)

  return splitTextInstances
}

export default useSplitTexts

import React, { forwardRef } from 'react'
import classnames from 'classnames/bind'
import RichText from 'components/typo/RichText'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const TextWithLink = forwardRef(({ className, text, ...rest }, ref) => {
  return (
    <div className={cx(className, css.TextWithLink)} ref={ref}>
      {text && (
        <RichText
          {...rest}
          text={text}
          markdownProps={{
            disallowedTypes: ['paragraph'],
            unwrapDisallowed: true,
          }}
        />
      )}
    </div>
  )
})

export default TextWithLink

import React, { useRef } from 'react'

import useTimeline from 'hooks/useTimeline'
import useAnimateInView from 'hooks/useAnimateInView'

import NewHero from 'components/shared/NewHero'
import Strapi5Hero from 'components/shared/Strapi5hero'

const HomeHero = ({ hero }) => {
  const assetWrapperRef = useRef()

  const animateIn = useTimeline({ paused: true, delay: 0.5 }, (tl) => {
    tl.fromTo(
      assetWrapperRef.current,
      {
        y: 50,
      },
      {
        y: 0,
        ease: 'elastic.out(0.3,0.3)',
        duration: 0.6,
      }
    )
    tl.fromTo(
      assetWrapperRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: 'none',
        duration: 0.1,
      },
      '<'
    )
  })

  const [inViewRef] = useAnimateInView({
    triggerOnce: true,
    animateIn: () => animateIn.play(),
  })

  return (
    <>
      {hero.strapi5Hero && <Strapi5Hero forwardRef={inViewRef} {...hero} />}
      {!hero.strapi5Hero && <NewHero forwardRef={inViewRef} {...hero} />}
    </>
  )
}

HomeHero.defaultProps = {}

export default HomeHero

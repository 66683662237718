import { useCallback } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { useInView } from 'react-intersection-observer'

const useAnimateInView = ({ animateIn, animateOut, ref, ...inViewParams }) => {
  const [inViewRef, inView, entry] = useInView({ ...inViewParams })

  useIsomorphicLayoutEffect(() => {
    if (inView) {
      animateIn && animateIn(entry)
    } else {
      animateOut && animateOut(entry)
    }
  }, [inView])

  const setRef = useCallback(
    (node) => {
      ref && (ref.current = node)
      inViewRef(node)
    },
    [inViewRef]
  )

  return [setRef, inView]
}

export default useAnimateInView

import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

const useTimelinePlayback = (tl, playback) => {
  useIsomorphicLayoutEffect(() => {
    if (tl) {
      playback ? tl.play() : tl.reverse()
    }
  }, [playback])
}

export default useTimelinePlayback

import React, { cloneElement } from 'react'
import classnames from 'classnames'

import css from './styles.module.scss'

const Iframe = ({ children, className }) => {
  return (
    <div className={classnames(css.Iframe, className)}>
      {cloneElement(children, {
        // fix cookie-bot blocking
        // https://support.cookiebot.com/hc/en-us/articles/360003790854-Iframe-cookie-consent-with-YouTube-example
        'data-cookieconsent': 'marketing',
      })}
    </div>
  )
}

export default Iframe

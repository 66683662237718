import React from 'react'

import Image from 'components/shared/Image'
import Link from 'components/shared/Link'

import delve from 'dlv'
import classnames from 'classnames/bind'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const ClientLogo = ({ className, link, logo, greyLogo, isStrapi5Hero }) => {
  const isLink = delve(link, 'href')
  const Component = isLink ? Link : 'div'
  const coloredOnly = !(greyLogo && greyLogo.media)

  return (
    <Component
      className={cx(css.ClientLogo, className, {
        [css.margin]: !isStrapi5Hero,
        animateOnHover: !coloredOnly,
      })}
      rel="noreferrer"
      {...(isLink ? link : {})}
    >
      {logo && (
        <Image
          className={cx(css.logo, css.coloredLogo, {
            coloredOnly,
          })}
          fluid
          fit="contain"
          {...logo}
        />
      )}
      {greyLogo && greyLogo.media && (
        <Image
          className={cx(css.logo, css.greyLogo)}
          fluid
          fit="contain"
          {...greyLogo}
        />
      )}
    </Component>
  )
}

ClientLogo.defaultProps = {}

export default ClientLogo

const [DOC, GUIDE] = ['developer-docs', 'user-docs']

const serializeResourceDocumentation = (hit) => {
  const highestLevel = hit.hierarchy.lvl0
    ? 0
    : hit.hierarchy.lvl1
    ? 1
    : hit.hierarchy.lvl2
    ? 2
    : hit.hierarchy.lvl3
    ? 3
    : hit.hierarchy.lvl4
    ? 4
    : hit.hierarchy.lvl5
    ? 5
    : null

  const lowestLevel = hit.hierarchy.lvl5
    ? 5
    : hit.hierarchy.lvl4
    ? 4
    : hit.hierarchy.lvl3
    ? 3
    : hit.hierarchy.lvl2
    ? 2
    : hit.hierarchy.lvl1
    ? 1
    : hit.hierarchy.lvl0
    ? 0
    : null

  if (highestLevel === null) return null

  return {
    ...(hit.hierarchy[`lvl${lowestLevel}`] && {
      title: hit.hierarchy[`lvl${lowestLevel}`],
    }),
    ...(hit.hierarchy[`lvl${highestLevel}`] && {
      subtitle: hit.hierarchy[`lvl${highestLevel}`],
    }),
    ...(hit.url && {
      link: { href: hit.url },
      type: hit.url.includes('dev-docs') ? DOC : GUIDE,
    }),
  }
}

const serializeResourcePartner = (hit) => {
  return {
    ...(hit.title && { title: hit.title }),
    ...(hit.metaDescription && { subtitle: `${hit.metaDescription}` }),
    ...(hit.slug && {
      link: { href: `${process.env.WEBSITE_URL}/partners/${hit.slug}` },
    }),
  }
}

const serializeResourcePlugin = (hit) => {
  return {
    ...(hit.hierarchy.lvl0 && { title: hit.hierarchy.lvl0 }),
    ...(hit.content && { subtitle: `${hit.content}` }),
    ...(hit.url && {
      link: {
        href: hit.url,
      },
    }),
  }
}

const serializeResourceProvider = (hit) => {
  return {
    ...(hit.hierarchy.lvl0 && { title: hit.hierarchy.lvl0 }),
    ...(hit.content && { subtitle: `${hit.content}` }),
    ...(hit.url && {
      link: {
        href: hit.url,
      },
    }),
  }
}

const serializeResourceIntegration = (hit) => {
  return {
    ...(hit.title && { title: hit.title }),
    ...(hit.description && { subtitle: `${hit.description}` }),
    ...(hit.slug && {
      link: {
        href: `${process.env.WEBSITE_URL}/integrations/${hit.slug}`,
      },
    }),
  }
}

// const serializeResourceStarter = (hit) => {
//   return {
//     ...(hit.description && { title: hit.description }),
//     ...(hit.integration && { subtitle: hit.integration }),
//     ...(hit.slug && {
//       link: { href: `${process.env.WEBSITE_URL}/starters/${hit.slug}` },
//     }),
//   }
// }

// const serializeResourceTutorial = (hit) => {
//   return {
//     ...(hit.title && { title: hit.title }),
//     ...(hit.stack && { subtitle: hit.stack.join(', ') }),
//     ...(hit.link && { link: { href: hit.link } }),
//   }
// }

const serializeResourceBlogPost = (hit) => {
  return {
    ...(hit.title && { title: hit.title }),
    ...(hit?.timeToRead && {
      subtitle: `${hit.metaDescription} ${hit?.timeToRead} min read`,
    }),
    ...(hit.slug && {
      link: { href: `${process.env.WEBSITE_URL}/blog/${hit.slug}` },
    }),
  }
}

export const algoliaResoucesAdapter = (hits, indice) => {
  switch (indice.name) {
    case 'documentation':
      return hits.map((hit) => serializeResourceDocumentation(hit))
    // case 'starters':
    //   return hits.map((hit) => serializeResourceStarter(hit))
    // case 'tutorials':
    //   return hits.map((hit) => serializeResourceTutorial(hit))
    case 'blog_posts':
      return hits.map((hit) => serializeResourceBlogPost(hit))
    case 'partners':
      return hits.map((hit) => serializeResourcePartner(hit))
    case 'plugins':
      return hits.map((hit) => serializeResourcePlugin(hit))
    case 'providers':
      return hits.map((hit) => serializeResourceProvider(hit))
    case 'integrations':
      return hits.map((hit) => serializeResourceIntegration(hit))
  }
}

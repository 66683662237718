import React, { useRef, useReducer } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

import classnames from 'classnames/bind'

import CloseIcon from 'components/icons/CloseIcon'
import { RichText } from 'components/typo'

import useTimeline from 'hooks/useTimeline'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function reducer(state, action) {
  switch (action.type) {
    case 'openBanner':
      return { ...state, renderBanner: true, bannerIsOpen: true }
    case 'closeBanner':
      return { ...state, bannerIsOpen: false }
    case 'unmountBanner':
      return { ...state, renderBanner: false }
    default:
      return state
  }
}

const Banner = ({ className, banner }) => {
  const bannerRef = useRef()
  const [{ bannerIsOpen, renderBanner }, dispatch] = useReducer(reducer, {
    bannerIsOpen: false,
    renderBanner: false,
  })
  const openBanner = useTimeline(
    {
      paused: true,
      onReverseComplete: () => {
        dispatch({ type: 'unmountBanner' })
      },
    },
    (tl) => {
      const duration = 0.6
      const ease = 'power3.inOut'

      if (bannerRef.current) {
        tl.to(bannerRef.current, {
          y: '0%',
          z: 1000,
          force3D: true,
          duration,
          ease,
        })
      }
    }
  )

  const checkBannerDisplay = () => {
    if (!banner) {
      return false
    }
    const store = JSON.parse(
      localStorage.getItem(process.env.BANNER_STORAGE_KEY)
    )
    const publishDate = Date.parse(banner.publishDate)

    if (store && store.date >= publishDate) {
      if (store.display) return true

      return false
    }

    const obj = {
      date: publishDate,
      display: true,
    }

    localStorage.setItem(process.env.BANNER_STORAGE_KEY, JSON.stringify(obj))

    return true
  }

  useIsomorphicLayoutEffect(() => {
    const bannerShouldDisplay = checkBannerDisplay()

    if (bannerShouldDisplay) {
      dispatch({ type: 'openBanner' })
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (bannerIsOpen) {
      openBanner.play()
    } else {
      openBanner.reverse()
    }
  }, [bannerIsOpen])

  useIsomorphicLayoutEffect(() => {
    if (!bannerIsOpen) {
      let store = JSON.parse(
        localStorage.getItem(process.env.BANNER_STORAGE_KEY)
      )

      if (store) store.display = false
      else
        store = {
          date: Date.parse(banner.publishDate),
          display: false,
        }

      localStorage.setItem(
        process.env.BANNER_STORAGE_KEY,
        JSON.stringify(store)
      )
    }
  }, [bannerIsOpen])

  return (
    <div ref={bannerRef} className={cx(css.banner, className)}>
      {renderBanner && (
        <RichText
          type="Text"
          text={banner.content}
          className={css.bannerRichText}
        >
          <span onClick={() => dispatch({ type: 'closeBanner' })}>
            <CloseIcon color="white" />
          </span>
        </RichText>
      )}
    </div>
  )
}

Banner.defaultProps = {}

export default Banner

import React from 'react'
import Link from 'next/link'
import css from './styles.module.scss'

const PreviewBanner = () => {
  return (
    <div className={css.PreviewBanner}>
      <p>
        Preview mode enabled. Click
        <Link href="/api/exit-preview" className={css.link}>
          here
        </Link>
        to disable.
      </p>
    </div>
  )
}

export default PreviewBanner

import { useState, useCallback } from 'react'
import useOnResize from 'hooks/useOnResize'

const useResizeIndex = () => {
  const [resizeIndex, setResizeIndex] = useState(0)

  const onResize = useCallback(() => {
    setResizeIndex((prevResizeIndex) => prevResizeIndex + 1)
  }, [resizeIndex])

  useOnResize(onResize)
  
return resizeIndex
}

export default useResizeIndex

import classnames from 'classnames/bind'
import delve from 'dlv'
import dynamic from 'next/dynamic'
import React, { forwardRef, useMemo } from 'react'
import Img from './Img'

import css from './styles.module.scss'

const LottieImg = dynamic(() => import('./LottieImg'))

const cx = classnames.bind(css)

function checkIfLottieAnimation(lottieAnimation) {
  return (
    lottieAnimation && (lottieAnimation.animationData || lottieAnimation.path)
  )
}
const Image = forwardRef(
  (
    {
      className,
      src,
      style,
      alt,
      media,
      format = 'large',
      loading = 'lazy',
      fit = 'cover',
      position = 'center',
      lottieAnimation,
      forcedRatio,
      forceShadow,
      fluid = false,
      forcePlayAnimation,
      play,
      notAbsolute,
    },
    ref
  ) => {
    const cx = classnames.bind(css)
    const memoMedia = useMemo(() => {
      return media?.data
        ? { id: media.data.id, ...media.data.attributes }
        : media
    }, [media])

    const isLottieAnimation =
      // process.env.NODE_ENV !== 'development' &&
      checkIfLottieAnimation(lottieAnimation)

    const width = delve(memoMedia, 'width', 0)
    const height = delve(memoMedia, 'height', 0)

    const ratioStyle = !fluid
      ? {
          paddingBottom: `${
            forcedRatio ? forcedRatio * 100 : (height / width) * 100
          }%`,
        }
      : {}

      const ratioClassName = cx(
        css.ratio,
        {
          [css.notAbsoluteFluid]: notAbsolute, 
          [css.fluid]: fluid && !notAbsolute,  
        }
      );
  
      const imgClassName = cx(
        notAbsolute ? css.notAbsoluteImg : css.img 
      );

    return memoMedia || src || fluid ? (
      <div className={className} style={style}>
    <div className={ratioClassName} style={ratioStyle}
        >
          {isLottieAnimation ? (
            <LottieImg
              ref={ref}
              lottieAnimation={lottieAnimation}
              forceShadow={forceShadow}
              forcePlayAnimation={forcePlayAnimation}
              play={play}
            />
          ) : (
            <Img
            ref={ref}
            media={memoMedia}
            src={src}
            alt={alt}
            loading={loading}
            fit={fit}
            position={position}
            className={imgClassName}
            format={format}
          />
          )}
        </div>
      </div>
    ) : null
  }
)

Image.defaultProps = {}

export default Image

import { useRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { lock, unlock } from 'tua-body-scroll-lock'

function useBodyLockScroll(shouldLockBody, ref) {
  const firstRender = useRef(true)

  useIsomorphicLayoutEffect(() => {
    if (shouldLockBody) {
      lock(ref ? ref.current : undefined)
    } else {
      unlock(ref ? ref.current : undefined)
    }
  }, [shouldLockBody])

  useIsomorphicLayoutEffect(() => {
    return () => unlock()
  }, [])

  useIsomorphicLayoutEffect(() => {
    firstRender.current = false
  }, [])
}

export default useBodyLockScroll

import React, { forwardRef } from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as Search } from './search.svg'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const InputText = ({ innerRef, icon, onChange, className, ...rest }) => {
  return (
    <div className={cx(css.InputText, className)}>
      <Search className={css.icon} />
      <input
        ref={innerRef}
        onChange={() => onChange(innerRef.current && innerRef.current.value)}
        type="text"
        {...rest}
      />
    </div>
  )
}

export default forwardRef((props, ref) => (
  <InputText innerRef={ref} {...props} />
))

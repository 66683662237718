import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import viewport from 'services/viewport'

const useOnResize = (callback) => {
  useIsomorphicLayoutEffect(() => {
    viewport.on('resize', callback)
    
return () => {
      viewport.off('resize', callback)
    }
  }, [])
}

export default useOnResize

import React from 'react'

import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const Layout = ({ className, children, tag, type, forwardRef, ...rest }) => {
  const Component = tag
  
return (
  <Component ref={forwardRef} className={cx(className, css[type])} {...rest}>
    {children}
  </Component>
  )
}

Layout.defaultProps = {
  tag: 'div',
  type: 'maxWidth',
}

export default Layout

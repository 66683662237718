import React, { createContext, useContext, useState } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

import { useInView } from 'react-intersection-observer'

export const HasScrolledContext = createContext([{}, () => {}])

export default function HasScrolledProvider({ children }) {
  const useHasScrolledState = useState(false)
  
return (
  <HasScrolledContext.Provider value={useHasScrolledState}>
    {children}
  </HasScrolledContext.Provider>
  )
}

export function useHasScrolledContext() {
  return useContext(HasScrolledContext)
}

export function DetectHasScrolled() {
  const [inViewRef, inView] = useInView()
  const [, setHasScrolledContext] = useHasScrolledContext()

  useIsomorphicLayoutEffect(() => {
    setHasScrolledContext(!inView)
  }, [inView])

  return (
    <div
      ref={inViewRef}
      style={{ position: 'absolute', top: '0px', width: '100%', left: '0px' }}
    />
  )
}

import { useRef, useCallback, useState } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import Inrtia from 'inrtia'
import useTicker from 'hooks/useTicker'

const defaultConfig = {
  friction: 50,
  rigidity: 0.15,
  interpolation: 'elastic',
  perfectStop: true,
  precisionStop: 0.001,
}

const useSpring = ({ config = {}, onUpdate, ...initialValue }) => {
  const [isRunning, setIsRunning] = useState(true)
  const inrtia = useRef(
    new Inrtia({
      value: initialValue,
      ...{ ...defaultConfig, ...config },
    })
  )
  const prevValue = useRef(initialValue)

  const onFrame = useCallback(
    (time, dTime) => {
      inrtia.current.update(Math.min(dTime, 64))
      onUpdate && onUpdate(inrtia.current.value, prevValue.current)
      prevValue.current = { ...inrtia.current.value }
    },
    [onUpdate]
  )

  useTicker(onFrame, !isRunning)

  useIsomorphicLayoutEffect(() => {
    return () => {
      inrtia.current.stop()
    }
  }, [])

  const set = useCallback(({ config, ...value }) => {
    if (config) {
      const { precisionStop, perfectStop, ...interpolationParams } = config
      inrtia.current.interpolationParams = {
        ...inrtia.current.interpolationParams,
        ...interpolationParams,
      }

      if (precisionStop) inrtia.current.precisionStop = precisionStop

      if (perfectStop) inrtia.current.perfectStop = perfectStop
    }
    inrtia.current.to(value)
  }, [])
  const stop = useCallback(() => setIsRunning(false), [])
  const start = useCallback(() => setIsRunning(true), [])

  return [set, stop, start]
}

export default useSpring

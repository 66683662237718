import React, { useRef, useCallback, useMemo } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import delve from 'dlv'
import classnames from 'classnames/bind'
import useSetStyle from 'hooks/useSetStyle'

import useSpring from 'hooks/useSpring'
import useOnResize from 'hooks/useOnResize'
import { gsap } from 'gsap'

import typo from 'components/typo/typography.module.scss'
import Push from './Push'
import LinksSection from './LinksSection'
import ArrowSection from './ArrowSection'
import styles from './styles.module.scss'
import { Text } from 'components/typo'
import Link from 'next/link'
import Image from 'next/legacy/image'
import ButtonIconsSection from './ButtonIconsSection'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const defaultInterpolationConfig = { interpolation: 'basic', friction: 3 }

const interpolationConfig = {
  transition: defaultInterpolationConfig,
  opening: { ...defaultInterpolationConfig, friction: 9 },
  closing: { ...defaultInterpolationConfig, friction: 1 },
}

const Panel = ({
  className,
  sections,
  pushs,
  onLinkClick,
  isVisible,
  setPanelRect,
  panelId,
  arrowLinks,
  panelStatus,
  lowerLinks,
  buttonIcons,
}) => {
  const panelRef = useRef()
  const cardRef = useRef()
  const linksRef = useRef()
  const pushsRef = useRef()

  const setPanelStyle = useSetStyle(panelRef)
  const [setPanelSpring] = useSpring({
    progress: 0,
    config: defaultInterpolationConfig,
    onUpdate: ({ progress }) => {
      setPanelStyle({
        autoAlpha: gsap.utils.normalize(
          panelStatus === 'opening' ? 0.6 : 0,
          1,
          progress
        ),
        y: (1 - progress) * -30,
      })
    },
  })

  const [setPanelYSpring] = useSpring({
    progress: 0,
    config: {
      friction: 90,
      rigidity: 0.12,
    },
    onUpdate: ({ progress }) => {
      setPanelStyle({
        y: panelStatus === 'opening' ? (1 - progress) * -35 : 0,
        z: panelStatus === 'opening' ? (1 - progress) * -30 : 0,
        transformPerspective: 1000,
      })
    },
  })

  const onResize = useCallback(() => {
    if (cardRef.current && setPanelRect) {
      const wrapper = cardRef.current.getBoundingClientRect()
      const content = linksRef.current.getBoundingClientRect()
      setPanelRect(panelId, wrapper, content)
    }
  }, [cardRef.current, panelId])

  useOnResize(onResize)

  useIsomorphicLayoutEffect(() => {
    setPanelSpring({
      config: interpolationConfig[panelStatus],
      progress: isVisible ? 1 : 0,
    })
    setPanelYSpring({ progress: isVisible ? 1 : 0 })
  }, [panelId, isVisible, panelStatus])

  const memoizedImage = useMemo(
    () => (lowerLink) =>
      (
        <Image
          src={`${lowerLink.icons[0]?.media?.data?.attributes?.url}`}
          alt={
            lowerLink.icons?.[0]?.media?.data?.attributes?.alternativeText ||
            lowerLink.icons?.[0]?.media?.data?.attributes?.name
              ?.split('/')
              .pop()
          }
          width={20}
          className={css.linkIcon}
          height={20}
        />
      ),
    []
  )

  return (
    <div ref={panelRef} className={cx(css.Panel, className)}>
      <div className={css.cardContent}>
        <div ref={cardRef} className={css.card}>
          <div ref={linksRef} className={cx(css.linksWrapper, { isVisible })}>
            <div
              className={
                pushs?.length > 0 ? css.linkSectionsHasPush : css.linkSections
              }
            >
              {sections.map((section, id) => {
                return (
                  <LinksSection
                    key={id}
                    title={section.title}
                    links={section.links}
                    onLinkClick={onLinkClick}
                    sectionsLength={sections.length}
                  />
                )
              })}
              {buttonIcons && <ButtonIconsSection buttonIcons={buttonIcons} />}
            </div>
            {arrowLinks &&
              arrowLinks.length > 0 &&
              arrowLinks.map((arrowLink) => {
                const id = delve(arrowLink, 'id')
                const text = delve(arrowLink, 'text')
                const title = delve(arrowLink, 'title')
                const icons = delve(arrowLink, 'icons')

                return (
                  <ul className={css.arrowLinks}>
                    <ArrowSection
                      key={id}
                      title={title}
                      text={text}
                      icons={icons}
                      link={arrowLink}
                      onClick={onLinkClick}
                    />
                  </ul>
                )
              })}
          </div>
          {pushs && pushs.length > 0 && (
            <div ref={pushsRef} className={css.pushs}>
              {pushs.map((push, id) => {
                return (
                  <Push
                    key={id}
                    onLinkClick={onLinkClick}
                    image={push.image}
                    title={push.title}
                    link={push.link}
                  />
                )
              })}
            </div>
          )}
        </div>
        <div className={css.lowerSection}>
          {lowerLinks && lowerLinks.length > 0
            ? lowerLinks.map((lowerLink, id) => (
                <div key={id}>
                  {lowerLink.href && (
                    <Link className={css.lowerLinks} href={lowerLink.href}>
                      {lowerLink.icons &&
                        lowerLink.icons.length > 0 &&
                        memoizedImage(lowerLink)}
                      <Text
                        className={css.linkTitle}
                        tag="span"
                        size="smaller"
                        theme="grey-900"
                      >
                        {lowerLink.text}
                      </Text>
                    </Link>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

export default Panel

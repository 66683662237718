import { useState } from 'react'
import classnames from 'classnames/bind'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const CliBoxCopy = ({ cliContent, theme = 'light' }) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(cliContent).then(() => {
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000) // Reset the success message after 2 seconds
    })
  }

  return (
    <div
      className={cx(
        css.codeContainer,
        theme === 'light' ? css.light : css.dark
      )}
    >
      <pre className={css.code}>{cliContent}</pre>
      <button className={css.copyButton} onClick={handleCopy}>
        {copySuccess ? 'Copied!' : 'Copy'}
      </button>
    </div>
  )
}

export default CliBoxCopy

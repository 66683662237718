import React from 'react'
import { useCopyToClipboard } from 'react-use'

import SyntaxHighlighter from './SyntaxHighlighter'

import CopyIcon from 'components/icons/CopyIcon'

import css from 'components/shared/CliBox/styles.module.scss'

const Code = ({
  className,
  language,
  content,
  theme = 'tomorrow',
  noMargin,
}) => {
  const [_, copyToClipboard] = useCopyToClipboard()

  return content ? (
    <>
      {!content.includes('npx create-strapi-app') && ( // Not adding it on the side copy/paste box in blog pages
        <button
          style={{
            float: 'right',
            right: '40px',
            top: '20px',
            position: 'relative',
          }}
          onClick={() => copyToClipboard(content)}
          className={css.copyIcon}
        >
          <CopyIcon />
        </button>
      )}

      <SyntaxHighlighter
        className={className}
        theme={theme}
        language={language}
        customStyle={{ margin: `${noMargin ? 0 : '35px'} 0` }}
      >
        {content}
      </SyntaxHighlighter>
    </>
  ) : null
}

export default Code

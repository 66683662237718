import React, { useRef, useCallback } from 'react'
import delve from 'dlv'
import { Title, RichText, Label } from 'components/typo'
import Button from 'components/shared/Button'
import TextWithLink from 'components/shared/TextWithLink'
import CliBox from 'components/shared/CliBox'
import NewsWithLink from 'components/shared/NewsWithLink'

import classnames from 'classnames/bind'
import useRefs from 'hooks/useRefs'
import useLabelTitleTextAnimation from 'hooks/useLabelTitleTextAnimation'
import useAnimateInView from 'hooks/useAnimateInView'
import useComponentSize from '@rehooks/component-size'
import useResizeIndex from 'hooks/useResizeIndex'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function checkIfLinks(button, smallTextWithLink) {
  return !!(
    (button && button.length) ||
    (smallTextWithLink && smallTextWithLink.length)
  )
}

const LabelTitleText = ({
  label,
  title,
  button,
  text,
  children,
  className,
  center,
  mobileCenter,
  linkProps,
  buttonProps,
  textWithLinkProps,
  titleProps,
  textProps,
  labelProps,
  theme,
  ctaBanner,
  beforeChildren,
  smallTextWithLink,
  noAnimation,
  cliContent,
  newsWithLink,
  newsWithLinkCustomTextColor,
}) => {
  const componentRef = useRef()
  const elementRef = useRef()
  const labelRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const childrenContainerRef = useRef()
  const buttonsRef = useRefs(button && button.length)
  const linksRef = useRefs(smallTextWithLink && smallTextWithLink.length)
  const smallTextWithLinkEntry = delve(smallTextWithLink, '0')
  const alignUnderButton = delve(smallTextWithLinkEntry, 'alignUnderButton')
  const { width, height } = useComponentSize(componentRef)
  const resizeIndex = useResizeIndex()
  const tl =
    !noAnimation &&
    useLabelTitleTextAnimation(
      {
        elementRef,
        components: [
          {
            key: 'label',
            ref: labelRef,
            type: 'splitText',
          },
          {
            key: 'title',
            ref: titleRef,
            type: 'splitText',
          },
          {
            key: 'text',
            ref: textRef,
            type: 'splitText',
          },
          {
            ref: childrenContainerRef,
            type: 'zoom',
          },
          {
            ref: buttonsRef,
            type: 'zoom',
          },
          {
            ref: linksRef,
            type: 'zoom',
          },
        ],
        wordsClass: css.word,
        delay: 0.3,
      },
      [width, height, resizeIndex]
    )

  const [inViewRef] = useAnimateInView(
    {
      triggerOnce: true,
      ref: componentRef,
      animateIn: () => !noAnimation && tl.restart(true),
    },
    [width, height, resizeIndex]
  )

  const setRef = useCallback((node) => {
    elementRef.current = node
    inViewRef(node)
  }, [])

  const renderRichText = () => {
    const { className, ...rest } = textProps

    return (
      <RichText
        forwardRef={textRef}
        type="Text"
        text={text}
        linksTheme={theme}
        markdownProps={{
          disallowedTypes: ['paragraph'],
          unwrapDisallowed: true,
        }}
        className={cx(css.text, className)}
        carriageReturn
        {...rest}
      />
    )
  }

  return (
    <div
      className={cx(css.LabelTitleText, className, {
        center,
        mobileCenter,
        ctaBanner,
        noAnimation,
      })}
      ref={!noAnimation ? setRef : null}
    >
      {beforeChildren && beforeChildren()}
      {label && (
        <Label
          tag="div"
          className={cx(css.label, labelProps?.className)}
          theme={theme}
          {...labelProps}
        >
          <div className={css.animateLabel} ref={labelRef}>
            {label}
          </div>
        </Label>
      )}
      {newsWithLink && (
        <NewsWithLink
          {...newsWithLink}
          newsWithLinkCustomTextColor={newsWithLinkCustomTextColor}
        />
      )}
      {title && (
        <Title
          size="large"
          {...titleProps}
          className={cx(css.title, titleProps?.className)}
        >
          <div className={css.titleContainer}>
            <span ref={titleRef}>{title}</span>
          </div>
        </Title>
      )}
      {text && renderRichText()}
      {cliContent && (
        <div className={css.cliContainer}>
          <CliBox content={cliContent} />
        </div>
      )}
      {checkIfLinks(button, smallTextWithLink) && (
        <ul className={css.links}>
          {button &&
            button.map(({ className, ...rest }, index) => {
              return (
                <li className={cx(css.link, theme)} key={`button-${index}`}>
                  <Button
                    {...linkProps}
                    {...buttonProps}
                    {...rest}
                    id={`${rest.id}-intro-button-${index}`}
                    ref={buttonsRef.current[index]}
                    className={cx(
                      className,
                      css.button,
                      buttonProps?.className
                    )}
                  />
                </li>
              )
            })}
          {smallTextWithLink &&
            smallTextWithLink.map(({ className, ...rest }, index) => {
              return (
                <li
                  className={cx(className, css.link, {
                    alignUnderButton:
                      smallTextWithLinkEntry && alignUnderButton,
                  })}
                  key={`text-with-link-${index}`}
                >
                  <TextWithLink
                    {...linkProps}
                    {...textWithLinkProps}
                    {...rest}
                    id={`intro-link-${index}`}
                    className={css.smallTextWithLink}
                    ref={linksRef.current[index]}
                  />
                </li>
              )
            })}
        </ul>
      )}
      {children && (
        <div ref={childrenContainerRef}>
          {typeof children === 'function' ? children() : children}
        </div>
      )}
    </div>
  )
}

LabelTitleText.defaultProps = {
  textProps: {},
  titleProps: {},
  theme: 'purple',
  center: false,
}

export default LabelTitleText

import React from 'react'
import classnames from 'classnames/bind'

import Link from 'components/shared/Link'
import Image from 'components/shared/Image'
import { Title } from 'components/typo'
import Button from 'components/shared/Button'
import delve from 'dlv'

import typo from 'components/typo/typography.module.scss'
import useOnMouseEnter from 'hooks/useOnMouseEnter'
import styles from './styles.module.scss'

const css = Object.assign({}, styles, typo)

const cx = classnames.bind(css)

const Push = ({
  className,
  image,
  title,
  subTitle,
  text,
  link,
  onLinkClick,
}) => {
  const [, onMouseEnter, onMouseLeave] = useOnMouseEnter()

  return (
    <Link
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(css.Push, className)}
      onClick={onLinkClick}
      {...(link || image)}
    >
      {title && (
        <Title tag="p" size="smaller" className={css.title} theme="bay-blue">
          {title}
        </Title>
      )}
      <Image className={css.cover} {...image} alt="" />
      <div className={css.textContent}>
        <span className={css.label}>{subTitle}</span>
        <span className={css.content}>{text}</span>
        {link && (
          <Button
            buttonClassName={css.buttonLink}
            theme={'blue-500'}
            link={link}
            fullWidth
          >
            {delve(link, 'text')}
          </Button>
        )}
      </div>
    </Link>
  )
}

export default Push
